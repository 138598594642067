<template>
    <v-sparkline
        :value="value"
        :line-width="width"
        :stroke-linecap="lineCap"
        :type="type"
    >
        <template v-slot:label="item"> ${{ item.value }} </template>
    </v-sparkline>
</template>

<script>
export default {
    data: () => ({
        width: 8,
        radius: 10,
        padding: 8,
        lineCap: 'square',
        gradient: ['#00c6ff', '#F0F', '#FF0'],
        value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
        gradientDirection: 'top',
        fill: false,
        type: 'bar',
        autoLineWidth: false,
    }),
}
</script>

<style scoped></style>
